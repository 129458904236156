import React from 'react';
import Accordion from '../components/accordion';
import GoBackLink from '../components/go-back-link';
import Meta from '../components/meta';
import ModulesController from '../components/modules-controller';
import { useContent } from '../hooks/use-content';
import { PageData } from '../types/global.types';
import { IOperationalChangePage } from '../types/heartcore.types';

const OperationalChangePage = ({pageContext }:PageData<IOperationalChangePage>) => {
	const { meta, name, modules, parent, listOfChanges, affectedRoutes } = useContent<IOperationalChangePage>(pageContext);
	const affectedRoutesNames = affectedRoutes?.map(ar => `${ar.number} ${ar.title}`);

	return (
		<>
			<Meta {...meta} />
			<div className='operational-change-page box-wide'>
				<GoBackLink parent={parent}></GoBackLink>
				<div className='operational-change-page__content'>
					{affectedRoutesNames?.length > 0 && <span className='operational-change-page__content__routes'>{affectedRoutesNames.join(', ')}</span>}
					<h1 className='operational-change-page__content__headline'>{name}</h1>
				</div>
				{listOfChanges?.length > 0 && <Accordion headline={null} entries={listOfChanges} disableBoxWide={true} disableBottomMargin={false} disableTopMargin={false} contentTypeAlias={null} />}
			</div>
			<ModulesController modules={modules} />
		</>
	);
};

export default OperationalChangePage;
